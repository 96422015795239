@import '../../styles/propertySets.css';

.automktTitle {
  text-align: left;
  margin-bottom: 5px;
  font-size: 27px;
  margin-top: 0;
  @media (--viewportMedium) {
    font-size: 1.75em;
    line-height: 30px;
  }
}

.automktTitleSpan {
  border-bottom: 3px solid var(--highlightColor);
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  /* display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  } */
  position: relative;
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;
  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.automktRewards {
  color: var(--marketplaceColor);
  font-size: 16px;
}

.automktContent {
  font-size: 20px;
  color: var(--matterColorMediumGrey);
  font-weight: normal;
  margin-bottom: 23px;
}

@keyframes animationCurrentOffers {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.animation {
  animation-name: animationCurrentOffers;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.currentOffersBtn {
  @apply --marketplaceButtonStyles;
  background-color: var(--matterColorGolden);
  composes: animation;

  animation-delay: 0.8s;
  min-height: 32px;
  width: 162px;
  margin-top: 55px;
  display: flex;
  align-items: center;
  padding: 10px 25px 10px 25px;
  border-radius: 8px;

  &:hover,
  &:focus {
    background-color: var(--matterColorDarkGolden);
  }
}

.rightArrow {
  padding-left: 5px;
  height: 55px;
  width: 30px;
  display: flex;
}

.captialAccessContainer {
  display: flex;
}

.capitalAccess,
.maintenance {
  padding-bottom: 10px;
  margin-top: 19px;
  height: 66px;

  @media (--viewportMedium) {
    padding-bottom: 30px;
    height: auto;
    margin-top: 21px;
  }
}

.crypto {
  padding-bottom: 10px;
  height: 86px;
  margin-top: 11px;

  @media (--viewportMedium) {
    padding-bottom: 30px;
    height: auto;
    margin-top: 21px;
  }
}

.dottedLine {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.container {
  display: flex;
  flex-direction: row;
}

.imgsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 17px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  justify-content: space-between;
}

.headingSpan {
  border-top: 4px solid var(--marketplaceColor);
}

.heading {
  font-size: 22px;
  letter-spacing: 0px;
  margin-top: 19px;
  margin-bottom: 10px;
}
.subheading {
  margin: 0;
  color: var(--matterColorDark);
  @media (--viewportMedium) {
    font-size: 24px;
    font-weight: 700;
  }
}
.paragraph {
  color: var(--matterColor);
  font-weight: normal;
  font-family: 'Spectral', serif;
  font-size: 20px;
  margin-top: 0px;
  @media (--viewportMedium) {
    font-size: 24px;
    margin-top: 0px;
  }
}
.email {
  color: var(--matterColorDark);
  font-weight: 500;
  font-family: 'Spectral', serif;
  font-size: 20px;
  margin-top: 0px;
  @media (--viewportMedium) {
    font-size: 24px;
    margin-top: 0px;
  }
}
.more {
  color: var(--matterColorDark);
  background-color: var(--matterColorGolden);
  /* width: 100%; */
  /* margin: 0 -24px; */
  padding: 0.5rem;
  font-family: 'Spectral', serif;
  text-align: center;
  @media (--viewportMedium) {
    border-radius: 15px;
    font-size: 24px;
    font-weight: 700;
    width: 450px;
  }
}
.achor {
  color: var(--matterColorDark);
  font-weight: 600;
  font-size: 18px;
  @media (--viewportMedium) {
    font-size: unset;
  }
}
.paragraphli {
  color: var(--matterColor);
  font-weight: normal;
  margin-left: 2rem;
  font-family: 'Spectral', serif;
  font-size: 20px;
  margin-top: 0px;
  @media (--viewportMedium) {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0.5rem;
  }
}
.email {
  font-family: 'Spectral', serif;
  /* display: none; */
  @media (--viewportMedium) {
    display: block;

    color: var(--matterColor);
    font-weight: normal;
    /* background-color: var(--matterColorGolden); */
    /* margin-left: 2rem; */
    font-size: 20px;
    margin-top: 0px;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0.5rem;
  }
}
.emailOnMobile {
  /* width:100%; */
  color: var(--matterColor);
  font-weight: normal;
  background-color: var(--matterColorGolden);
  padding: 0.8rem 2rem;
  font-size: 20px;
  margin: 1rem -24px 0 -24px;
  font-size: 24px;
  @media (--viewportMedium) {
    display: none;
  }
}

.pHeading {
  color: var(--matterColorMediumGrey);
  font-weight: normal;
  font-size: 18px;

  @media (--viewPortMedium) {
    font-size: 19px;
  }
}

.span {
  /* border-top: 4px solid var(--marketplaceColor);
  padding-top: 5px; */
}

.text {
  margin-left: 17px;

  @media (--viewportMedium) {
    margin-left: 28px;
  }
}

.buttonLink {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.comingSoonImage {
  width: 100%;
}
.star {
  font-size: 30px;
  fill: var(--matterColorGolden);
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  margin-right: 0.25rem;
  border-radius: 20px;
  margin-bottom: 0.25rem;
}
.starSmall {
  font-size: 25px;
  fill: var(--matterColorGolden);
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  margin-right: 0.25rem;
  border-radius: 20px;
  margin-bottom: 0.25rem;
}
.italic {
  font-style: italic;
}
.umbrella {
  width: 400px;
  /* height: 400px; */
  @media (--viewportMedium) {
    position: absolute;

    top: 16%;
    /* height: 350px; */
    width: 350px;
    left: 65%;
  }

  @media (--viewportLarge) {
    top: 14%;
    /* height: 350px; */
    width: 350px;
    right: 4%;
  }
}
.borderContainer {
  position: relative;
  margin-bottom: 1rem;
}
.sectionTitle {
  margin-top: 0px;
  font-size: 27px;
  color: var(--marketplaceColor);
  font-weight: 700;
  /* position: absolute;
  top: -30%; */
  @media (--viewportMedium) {
    font-size: 50px;
    /* top: -70%; */
    line-height: 40px;
  }
}
.border {
  width: 150px;
  @media (--viewportMedium) {
    width: 250px;
  }
}
.list {
  list-style-type: disc;
}
.list li::marker {
  color: #000000;
}
