.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    width: 100%;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  hyphens: auto;
  margin-top: 8px;
  margin-bottom: 0;
}

.securedByStripe{
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  @media (--viewportMedium) {
    margin-bottom: 47px;
  }
  & p {
    font-weight: 600;
  }
  & img {
    height: 58px;
    @media (--viewportMedium) {
      height: 70px;
    }
  }
}
