@import '../../styles/propertySets.css';

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 42px 24px;
  background-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 0px 0;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 10px;
    /* padding-top: 41px; */
  }

  @media (--viewportLarge) {
    padding-top: 10px;
    /* padding-top: 73px; */

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1300px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1300px;
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin: 12px 0 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (--viewportMedium) {
    display: none;
  }
}
.web3 {
  background-color: var(--matterColorGolden);
  padding: 0.5rem 1rem;
  font-size: 20px;
  border-radius: 10px;
  font-weight: 600;
  color: black;
}
.web3Desktop {
  background-color: var(--matterColorGolden);
  padding: 0.5rem 1rem;
  font-size: 20px;
  border-radius: 10px;
  font-weight: 600;
  color: black;
  position: absolute;
  top: 40%;
  left: 0%;
}
.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}
.mobileContainer {
  display: flex;
  gap: 1rem;
}
/* Footer links */
.links {
  /* Layout */
  display: block;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;
  /* border-bottom: 2px solid var(--matterColorGolden); */
  height: unset;
  @media (--viewportMedium) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
    border-bottom: none;
    height: 228px;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 220px;
  display: none;
  margin-right: 24px;
  margin-bottom: 24px;
  gap: 0.5rem;
  @media (--viewportMedium) {
    display: flex;
    order: 1;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    flex-basis: 300px;
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 342px;
    margin-right: 0px;
    /* margin-right: 12px; */
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
  margin-top: 0rem;
  position: relative;
}

.organizationInfo {
}

.organizationDescription,
.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
  font-size: 16px;
  color: var(--matterColorLight);
}
.footerContent {
  color: var(--matterColorLight);
  margin-top: 12px;
}

.logo {
  width: 70%;
  height: 100px;
}

.horizontalLine {
  height: 10px;
}

.lineContainer {
  flex-direction: row;
}
.yellowBorder {
  background-color: var(--matterColorGolden);
  padding: 1px;
  display: block;
  margin-top: 2rem;
  @media (--viewportMedium) {
    display: none;
  }
}
.arrowLink {
  position: absolute;
  cursor: pointer;
  bottom: 50px;
  background-color: white;
  right: 25px;
  font-size: 60px;
  color: var(--marketplaceColor);
  border-radius: 50%;
  padding: 0.5rem;
  /* Only Show on Mobile Devices */
  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyright {
  margin-top: 24px;
}

.copyrightLink {
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex-basis: 190px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  @apply --marketplaceH5FontStyles;
  line-height: 20px;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--matterColorGolden);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
  }
}
.linkCategory {
  /* Font */
  @apply --marketplaceH5FontStyles;
  line-height: 20px;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--matterColorGolden);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.linkText {
  /* Font */
  @apply --marketplaceH5FontStyles;
  line-height: 20px;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  text-decoration: underline;
  margin-top: 0;
  margin-bottom: 12px;
  &:hover {
    color: var(--matterColorGolden);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.substack {
  fill: var(--matterColorLight);
  &:hover {
    fill: var(--matterColorGolden);
  }
}
/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 2rem;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 208px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
    display: flex;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  margin-right: 0.25rem;
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--matterColorGolden);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */

  /* Dimensions */
  height: 120px;
  margin-top: 2rem;

  padding: 10px 0px 18px 24px;

  /* Layout */
  /* display: flex;
  justify-content: space-between;
  align-items: flex-end; */

  background-color: var(--marketplaceColor);
  border-top: 2px solid var(--matterColorGolden);
  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  font-size: 13px;
  padding-top: 1rem;
  /* Tell parent component how much space this one prefers */
  /* flex-basis: 172px; */

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    /* color: var(--marketplaceColor); */
    text-decoration: none;
  }
}
.organizationCopyrightEnd {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  font-size: 13px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--matterColorGolden);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: none;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.privacy {
  margin-right: 0px;
  @media (--viewportMedium) {
    margin-right: 24px;
  }
}
.tiktok {
  font-size: 25px;
  fill: white;
  &:hover {
    fill: var(--matterColorGolden);
    text-decoration: none;
  }
}
.twitterIcon {
  color: white;
  font-size: 20px;
  &:hover {
    color: var(--matterColorGolden);
    text-decoration: none;
  }
}
