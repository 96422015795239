@import '../../styles/propertySets.css';

.pageTitle {
  text-align: left;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 48px auto;
  }
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.heading {
  margin: 0px;
  font-size: 27px;
  @media (--viewportMedium) {
    font-size: 1.75em;
    line-height: 30px;
  }
}

.earnTokens {
  color: black;
  font-weight: bolder;
  font-size: 17px;
  padding-top: 10px;

  @media (--viewportMedium) {
    padding-top: 20px;
  }
}

.hereLink {
  color: black;
  border-bottom: 3px solid var(--marketplaceColorLight);

  &:hover {
    border-bottom: none;
  }
}

@keyframes animationHowitworks {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.animation {
  animation-name: animationHowitworks;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.automktBtn {
  @apply --marketplaceButtonStyles;
  background-color: var(--matterColorGolden);
  composes: animation;

  animation-delay: 0.8s;
  padding: 0px 25px;
  min-height: 32px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  color: black;
  &:hover,
  &:focus {
    background-color: var(--matterColorDarkGolden);
  }
}

.rightArrow {
  padding-left: 5px;
  height: 55px;
  width: 30px;
  display: flex;
}

.video {
  max-height: 75vh;
  overflow: hidden;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  box-shadow: 5px 5px 5px 5px rgb(136 144 195 / 20%), 5px 5px 15px 5px rgb(37 44 97 / 20%);
  /* background-image: url(car.png); */
  border-radius: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 30px 0 50px 0;
  width: 100%;
  height: 280px;

  @media (--viewportMedium) {
    width: 100%;
    height: 400px;
  }
}

.iconPlay {
  cursor: pointer;
  position: relative;
  z-index: 3;
  &::before {
    content: '';
    position: absolute;
    z-index: -3;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 100px;
    height: 100px;
    background: var(--matterColorGolden);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }
  &::after {
    content: '';
    position: absolute;
    z-index: -2;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 100px;
    height: 100px;
    background: #ffffff;
    border-radius: 50%;
    transition: all 200ms;
  }
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.videoContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin: 40px 0px;
}

.blueLine {
  height: 10px;
  display: inline-block;
  margin: 0px;
  width: 5%;
  content: '';
  border-bottom: 4px solid var(--marketplaceColorLight);
  margin-bottom: 3px;
  @media (--viewportMedium) {
    width: 2.5%;
  }
}

.paragraph {
  color: var(--matterColorMediumGrey);
  font-size: 18px;
  font-weight: normal;
  margin-top: 0px;
}

.imagesContainer {
  display: flex;
  justify-content: center;
  & img {
    height: 2000px;
    margin: 20px 0px;
  }
}

.modalSizeContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 860px;
    min-height: auto;
    height: auto;
  }
}

.contentMain {
  @media (--viewportMedium) {
    /* max-width: 650px; */
  }
}

.buttonLink {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.video {
  width: 100%;
  height: auto;
  @media (--viewportMedium) {
    height: 500px;
    width: 100%;
  }
}
