.root {
}

.blueLine {
  height: 10px;
  display: inline-block;
  margin: 0px;
  width: 5%;
  content: '';
  border-bottom: 4px solid var(--marketplaceColorLight);
  @media (--viewportMedium) {
    width: 3%;
  }
  @media (--viewportLarge) {
    width: 3%;
  }
}

.paragraph {
  color: var(--matterColor);
  font-weight: normal;
  font-family: 'Spectral', serif;
  font-size: 20px;
  margin-top: 0px;
  @media (--viewportMedium) {
    font-size: 24px;
    margin-top: 0px;
  }
}
b {
  color: var(--matterColor);
}
.boldText {
  font-size: 17px;
  margin-top: 0px;
  font-weight: 600;
  margin-bottom: 30px;
  @media (--viewportMedium) {
    margin-bottom: 0px;
  }
}

.content {
  margin-bottom: 1.5rem;
}
.image {
  height: auto;
  width: 100%;
  @media (--viewportMedium) {
    margin: 20px 0px 30px 0px;
    height: 290px;
    width: auto;
  }
  @media (--viewportLarge) {
    height: 330px;
  }
}
.container {
  margin: 1rem 0rem;
}

.video {
  max-height: 75vh;
  overflow: hidden;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  box-shadow: 5px 5px 5px 5px rgb(136 144 195 / 20%), 5px 5px 15px 5px rgb(37 44 97 / 20%);
  /* background-image: url(car.png); */
  border-radius: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 30px 0 50px 0;
  width: 100%;
  height: 280px;

  @media (--viewportMedium) {
    width: 100%;
    height: 400px;
  }
}
.borderContainer {
  background: url('../../assets/border.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: auto;
}
.heroContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.contentWrapper {
  position: relative;
}
.logo {
  width: 100%;
  border-radius: 10px;
  opacity: 0.1;
  position: absolute;
  right: 0;
  @media (--viewportMedium) {
    width: 300px;
    height: 300px;
  }
}
.youtube {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: red;
  margin-bottom: 2rem;
  @media (--viewportMedium) {
    font-size: 30px;
    font-weight: 700;
  }
}
.arrowContainer {
  display: flex;
  gap: 5px;
  justify-content: start;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    gap: 1rem;
  }
}
.newsletter {
  margin: 0;
  color: var(--marketplaceColor);
  font-weight: 700;
  font-size: 20px;
  background-color: var(--matterColorGolden);
  width: 160px;
  padding-left: 1rem;
  border-radius: 15px;
  text-align: center;
  padding: 0.05rem;
  padding-right: 0;
  cursor: pointer;
  border: 2px solid var(--marketplaceColor);
  @media (--viewportMedium) {
    width: 200px;
  }
}
.arrow {
  color: white;
  font-size: 40px;
  background-color: var(--marketplaceColor);
  border-radius: 50%;
  padding: 0.25rem;
  margin-left: 1rem;
  margin-right: -10px;
}
.borderContainer {
  position: relative;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.sectionTitle {
  margin-top: 0px;
  font-size: 23px;
  color: var(--marketplaceColor);
  font-weight: 700;
  /* position: absolute;
  top: -30%; */
  @media (--viewportMedium) {
    font-size: 50px;
    /* top: -90%; */
    line-height: 40px;
  }
}
.border {
  width: 120px;
  @media (--viewportMedium) {
    width: 200px;
  }
}
.link {
  color: var(--matterColor);
  text-decoration: underline;
}
.star {
  /* display:none; */
  font-size: 40px;
  fill: var(--matterColorGolden);
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  margin-right: 0.25rem;
  height: 90px;

  border-radius: 50%;
  width: auto;
  margin-top: 5px;
  @media (--viewportMedium) {
    height: 120px;

    display: inline;
  }
}
.ratingSection {
  display: flex;
  gap: 20px;
  /* margin-bottom: 16px; */
  /* flex-direction: ; */
}
.ratingText {
  color: var(--matterColor);
  font-weight: normal;

  font-size: 20px;
  margin-top: 0px;
  @media (--viewportMedium) {
    font-size: 24px;
    margin-top: 0px;
  }
}
.videoContainer {
  height: 200px;
}
.emailMobile {
  color: var(--matterColor);
  font-weight: normal;
  font-size: 20px;
  font-family: 'Spectral', serif;
  @media (--viewportMedium) {
    display: none;
  }
}
.emailDesktop {
  /* display: none; */
  color: var(--matterColor);
  font-size: 20px;
  font-family: 'Spectral', serif;
  @media (--viewportMedium) {
    font-size: 24px;
    margin-top: 0px;
    display: block;
  }
}
.email {
  text-decoration: underline;
}
