.logoMobile {
  height: 90%;
  margin-left: 12px;
  border-radius: 0.5rem;
}

.logoDesktop {
  /* width: 46px; */
  height: 50px;
  border-radius: 0.5rem;
}

.logoFooter {
  width: 70px;
  border-radius: 10px;

  @media (--viewportMedium) {
    height: 60px;
    width: unset;
  }
}
.logoTextImage {
  height: 35px;
}
.logoTextImageM {
  height: 30px;
}
.text {
  position: relative;
}
.logoContainer {
  align-self: flex-start;
  height: 75%;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (--viewportXSmall) {
    height: 85%;

    margin-top: 0.3rem;
  }
}
.logoText {
  color: var(--matterColorDark);
  font-size: 1.3rem;
  font-weight: 600;
  margin-left: 0.5rem;
  @media (--viewportMedium) {
    font-size: 1.6rem;
    font-weight: 600;
    margin-left: 0.5rem;
    text-decoration: none;
  }
}
.mainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
