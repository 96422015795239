@import '../../styles/propertySets.css';

.youtubeWrapper{
  margin: 0px;
}

.youtubeContent{
  display: flex;
  flex-direction: column;
}

.youtubeContent p{
  font-size: 16px;
  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.youtubeContent > div{
  margin: 0px;
}

.tlcmktiFrame{
  border:1px solid #EEE;
  background:white;
  margin-left: 36px;
}

.icon img{
  width: 55px;
  margin-right: 1em;
  @media (--viewportMedium) {
    width: 72px;
  }
}

.youtubeHeader{
  display: flex;
  align-items: center;
}

.youtubeHeader h1{
  font-size: 18px;
  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.youtubeContent iframe{
  @media (--viewportMedium) {
    height: 550px;
  }
}

.videoConatiner{
  max-height: 75vh;
  overflow: hidden;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  box-shadow: 5px 5px 5px 5px rgb(136 144 195 / 20%), 5px 5px 15px 5px rgb(37 44 97 / 20%);
  background-image: url(car.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 210px;

  @media (--viewportMedium){
    width: 100%;
    height: 500px;
  }
}

.iframe{
  width: 100%;
  position: relative;
  padding-bottom: 70%;
  @media (--viewportMedium) {
    padding-bottom: 43%;
  }
}

.iframe iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
}

.iconPlay {
  cursor: pointer;
  position: relative;
  z-index: 3;
  &::before {
    content: "";
    position: absolute;
    z-index: -3;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 100px;
    height: 100px;
    background: var(--matterColorGolden);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }
  &::after {
    content: "";
    position: absolute;
    z-index: -2;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 100px;
    height: 100px;
    background: #ffffff;
    border-radius: 50%;
    transition: all 200ms;
  }
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.videoContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.video{
  width: 100%;
  height: 350px;
}

.youtube{
  color: var(--marketplaceColor);
}