@import '../../styles/propertySets.css';

.pageTitle {
  text-align: left;
  font-size: 25px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.span {
  border-top: 4px solid var(--marketplaceColor);
  padding-top: 5px;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 48px auto;
  }
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentMain {
  width: 90%;
  margin-top: 10px;
  & p {
    color: var(--matterColorMediumGrey);
    font-weight: normal;
    font-size: 18px;
    margin-top: 16px;
  }

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.bnplImage {
  width: auto;
  height: 330px;

  @media (--viewportMedium) {
    height: 500px;
    margin-left: 50px;
  }
}

.completedImage {
  width: auto;
  height: 345px;

  @media (--viewportMedium) {
    height: 500px;
    margin-left: 50px;
  }
}

.mailImage {
  width: auto;
  height: 250px;
  margin: 40px 0px;

  @media (--viewportMedium) {
    height: 300px;
    margin-left: 80px;
  }
}

.link {
  color: var(--matterColorMediumGrey);
  border-bottom: 3px solid var(--marketplaceColor);
  &::hover {
    border-bottom: none;
  }
}

.email {
  color: var(--marketplaceColorLight);
}

.listItem {
  font-size: 18px;
  font-weight: normal;
  margin: 12px 0 0 18px;
  color: var(--matterColorMediumGrey);
  list-style-type: disc;
}

.sectionOne {
}
.paragraph {
  color: var(--matterColor);
  font-weight: normal;
  font-family: 'Spectral', serif;
  font-size: 20px;
  margin-top: 0px;
  @media (--viewportMedium) {
    font-size: 24px;
    margin-top: 0px;
  }
}
.paragraph2 {
  color: var(--matterColor);
  font-weight: normal;
  font-family: 'Spectral', serif;
  font-size: 20px;
  margin-top: 0px;
  @media (--viewportMedium) {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0;
  }
}
.italyText {
  margin-left: 0.25rem;
}
.subheading {
  margin: 0;
  display: inline;
  color: var(--matterColorDark);
  @media (--viewportMedium) {
    font-size: 24px;
    font-weight: 700;
  }
}
.logo {
  width: 100%;
  border-radius: 10px;
  opacity: 0.1;
  position: absolute;
  right: 0;
  @media (--viewportMedium) {
    width: 300px;
    height: 300px;
  }
}
.paragraphli {
  color: var(--matterColor);
  font-weight: normal;
  list-style-type: disc;
  font-family: 'Spectral', serif;
  font-size: 20px;
  margin-left: 2rem;
  /* margin-top: 0px; */
  @media (--viewportMedium) {
    font-size: 24px;
    margin-bottom: 0.5rem;
    /* margin-top: 0px; */
  }
}
.imageWeb3 {
  width: 100%;
}
.sectionTwo,
.sectionThree {
  margin-top: 30px;
}
.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: -40px;
  }
}
.images {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    justify-content: space-between;
    flex-direction: row;
  }
}
.subheadingNew1 {
  display: block;
  text-align: center;
  background-color: var(--matterColorGolden);
  padding: 7px;
  border-radius: 10px;
  color: var(--matterColorDark);

  background-color: transparent;
  color: var(--marketplaceColor);

  font-size: 32px;
  font-weight: 700;
}
.subheadingNew2 {
  display: inline-block;
  text-align: center;
  background-color: var(--matterColorGolden);
  border-radius: 15px;
  padding: 10px;
  font-family: 'Spectral', serif;
  color: var(--matterColorDark);
  @media (--viewportMedium) {
    font-size: 24px;
    font-weight: 700;
  }
}
.marketHeader {
  margin-top: 0.5rem;
}
.marketSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.completed,
.coins {
  width: 100%;
  border-radius: 10px;
  @media (--viewportMedium) {
    width: 300px;
    height: 300px;
  }
}
.coins {
  @media (--viewportMedium) {
    width: 250px;
  }
}
.completed {
  opacity: 0.1;
}
.borderContainer {
  position: relative;
  margin-bottom: 1rem;
}
.sectionTitle {
  margin-top: 0px;
  font-size: 23px;
  color: var(--marketplaceColor);
  font-weight: 700;
  /* position: absolute;
  top: -30%; */
  @media (--viewportMedium) {
    font-size: 50px;
    /* top: -70%; */
    line-height: 40px;
  }
}
.border {
  width: 120px;
  @media (--viewportMedium) {
    width: 200px;
  }
}
.borderBlock {
  width: 250px;
  @media (--viewportMedium) {
    width: 400px;
  }
}
.heading {
  display: flex;
}
.videoContainer {
  height: 200px;
}
.rewards {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  @media (--viewportMedium) {
    flex-direction: row;
    gap: 3rem;
  }
}
.rewards p {
  flex-grow: 1;
}
.coins {
  margin-top: -20px;
  @media (--viewportMedium) {
    height: auto;

    order: 2;
  }
}
.item {
  width: 100%;
  @media (--viewportMedium) {
    width: 350px;
    /* height: 370px; */
  }
  @media (--viewportLarge) {
    width: 450px;
    /* height: 500px; */
  }
}
.italy {
  display: inline;
  font-weight: normal;

  font-size: 20px;
  @media (--viewportMedium) {
    display: none;
  }
}
.normal {
  display: none;
  @media (--viewportMedium) {
    font-size: 24px;
    font-weight: normal;

    display: inline;
  }
}
.legalMobile {
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
}
.legalDesktop {
  display: none;
  @media (--viewportMedium) {
    display: inline;
  }
}
