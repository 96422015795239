@import '../../styles/propertySets.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }
}
.ul {
  margin-left: 2.5rem;
}
.ul li {
  &::before {
    content: '\2022';
    color: black;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
.text {
  font-size: 16px;
  font-weight: 500;
}
