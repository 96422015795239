@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  @media (--viewportMedium) {
    margin-top: 1rem;
  }
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
  line-height: 1.3;
  font-size: 13px;
}

.termsText {
  /* @apply --marketplaceModalHelperText; */
  font-weight: 600;
  line-height: 1.2;
  font-size: 13px;
}

.termsLink {
  /* @apply --marketplaceModalHelperLink; */
  font-weight: 600;
  font-size: 13px;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.inputFontSize {
  font-size: 16px;
  border-bottom-color: var(--attentionColor);
}
.submitButton {
  font-size: 25px;
  font-weight: 700;
  @media (--viewportMedium) {
    font-size: 30px;
  }
}
