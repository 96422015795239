@import '../../styles/propertySets.css';

.root {
  position: relative;
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
    overflow-x: hidden;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    padding: 36px 36px 0 36px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.threeImgs {
  display: flex;
  gap: 10px;
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  width: 85%;
  margin-right: 2px;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    width: 100%;
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }

  @media (--viewportLarge) {
    margin-right: 15px;
  }

  @media (--viewportXLarge) {
    margin-right: 20px;
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -41px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -69px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}
.desktopPriceContainer {
  display: none;

  /* @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  } */
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    font-size: 35px;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--matterColor);
  font-size: 18px;
  line-height: 22px;
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.5rem;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH5FontStyles;
  margin-top: 7px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  @media (--viewportMedium) {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
  text-decoration: underline;
}

.contactWrapper {
  /* display: none; */
  display: inline;
  /* @media (--viewportMedium) {
 
  } */
}

.separator {
  margin: 0 6px;
}

.ratedStar {
  position: relative;
  bottom: 3px;
  border: 1px solid var(--marketplaceColor);
  padding: 0.5px 2px;
  padding-bottom: 0.25rem;
  background-color: var(--marketplaceColor);
  border-radius: 20px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 0;
  @media (--viewportMedium) {
    padding: 0;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  margin-top: 2px;
  margin-bottom: 13px;
  margin-left: 10px;
  @media (--viewportMedium) {
    margin-top: 2px;
    margin-bottom: 12px;
  }
}

.availabilityTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  margin-top: 7px;
  margin-bottom: 13px;
  margin-left: 10px;
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle,
.customFieldTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 31px;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
    margin-bottom: 31px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  margin: 31px 0 23px 10px;

  @media (--viewportMedium) {
    margin: 33px 0 27px 10px;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 260px);
  position: relative;
  width: 100%;
  border-radius: 15px;
  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
  font-size: 20px;
  @media (--viewportMedium) {
    font-size: 22px;
  }
}

.customFieldsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 36px;
    padding: 0;
  }
}

.customField {
  display: flex;
  align-items: center;
}

.customFieldValue {
  margin: 0px 0px 0px 5px;
}

.customFieldTitle {
  margin: 0;
  font-size: 16px;
}

.youtubeIframe {
  width: 100%;
  height: 250px;
  @media (--viewportMedium) {
    height: 315px;
  }
}

.scrollContainer {
  display: flex;
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation;

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
  }
}

.scrollArrow {
  -webkit-overflow-scrolling: touch;
  @media (--viewportMobile) {
    display: none;
  }
}

.ratingRating {
  display: flex;
  align-items: center;
}
.rating {
  margin-left: 6px;
  font-size: 18px;
}

.perMonth {
  color: var(--matterColor);
  margin-top: 0;
}
.iconI {
  height: 30px;
}

.calendarIcon {
  height: 35px;
}

.vendorIcon {
  height: 50px;
}

.iconIWrapper {
  display: flex;
  align-items: flex-start;
}

.vendorIconWrapper {
  display: flex;
  align-items: center;
}
.address {
  overflow: hidden;
  letter-spacing: 1;
  /* text-overflow: ellipsis; */
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 1; */
  /* -webkit-box-orient: vertical; */
}

.week {
  display: flex;
  flex-direction: column;
  border-top: solid 1px var(--matterColorNegative);
  border-right: solid 1px var(--matterColorNegative);
  border-left: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  margin: 0px 0px 23px 0px;

  & > .weekDay {
    border-bottom: solid 1px var(--matterColorNegative);
  }
}

.weekDay {
  @apply --marketplaceTabNavHorizontalFontStyles;
  display: flex;
  flex-direction: row;
  padding: 24px 12px 23px 12px;
  @media (--viewportMedium) {
    padding: 24px 24px 23px 24px;
  }
}

.dayOfWeek {
  width: 150px;
}

.blockedWeekDay {
  display: none;
}

.entries {
}

.entry {
  display: block;
}

.imageMaxWidth {
  line-height: 1.7;
  & img {
    max-width: 100%;
  }
  & iframe {
    max-width: 100%;
  }
  & p {
    line-height: 1.5;
  }
}

.availabilityPlanContainer {
  padding: 0 24px;
  @media (--viewportMedium) {
    padding: 0px;
  }
}
.categoryIcon {
  font-size: 2rem;
  color: #1a63d1;
}
.invoiceContainer {
  position: relative;
  border: 1px solid var(--matterColorNegative);
  margin-right: 0.25rem;
  border-radius: 6px;
  padding: 0.5rem;
  /* padding-right: 1rem; */
}

.invoices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.pdfIcon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: var(--matterColorGolden);
  cursor: pointer;
  stroke: none;

  &:hover {
    color: var(--matterColorDarkGolden);
  }
}
.call {
  border-radius: 10px;
  padding: 0.35rem 1rem;
  margin-right: 0.5rem;
  background-color: var(--matterColorGolden);
  color: var(--matterColorDark);
  font-size: 16px;
  font-weight: 600;
  &:hover {
    background-color: var(--matterColorDarkGolden);
    text-decoration: none;
  }
}
.town {
  margin: 0;
  padding: 0;
  line-height: 1;
}
.mapButton {
  position: absolute;
  top: 10px;
  right: 10px;
}
