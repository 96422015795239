.mapContainer {
  background-color: var(--matterColorLight);
  padding: 0.5rem 0.25rem;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
}
.lMapContainer {
  padding: 0.5rem 0.25rem;
  padding-top: 0;
  display: flex;
  gap: 0.5rem;
}
.button {
  background-color: var(--matterColorLight);
  color: var(--matterColorDark);
  border-radius: 4px;
  padding: 4px 8px;
  border: 1px solid var(--matterColorGolden);
  cursor: pointer;
  background-color: var(--matterColorGolden);
  &:hover {
    background-color: var(--matterColorDarkGolden);
  }
}
