@import '../../styles/propertySets.css';

.root {
}

.staticPageWrapper {
  width: calc(100% - 24px);
  margin: 12px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 5px auto 24px auto;
  }
  @media (--viewportLarge) {
    width: calc(100% - 72px);
    margin: 10px auto 24px auto;
  }
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 180px;
  height: 100%;
  padding: 0;

  @media (--viewportMedium) {
    min-height: unset;
    /* height: 73vh; */
    /* max-height: 500px; */
  }

  @media (--viewportLarge) {
    min-height: unset;
    /* min-height: 580px; */
    height: calc(85vh - var(--topbarHeightDesktop));
    /* max-height: 650px; */
    align-items: center;
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;

  @media (--viewportMedium) {
    padding-bottom: 0px;
    padding-top: 10px;
    margin-top: 10px;
    justify-content: space-between;
  }

  @media (--viewportLarge) {
    padding: 0px 15px 0px 15px;
    margin-top: 0px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}

.lpContentWrapper {
  width: 97%;
  margin: auto;
  max-width: 1300px;
  height: 100%;
}

.lpContentWrapper h2 {
  margin: 24px 0px;
  font-size: 21px;
  @media only screen and (min-width: 250px) and (max-width: 299px) {
    line-height: 48px;
  }
  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.promotedSectionTabMenu {
  margin: 24px 0px;
}

.sectionHowItWorks {
  margin-top: 36px;
}

.categoryHeader span {
}

@keyframes animation_userOwnership {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.animation {
  animation-name: animation_userOwnership;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.heading {
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: var(--matterColorLight);
  padding: 11px 12px;
  @media (--viewportMedium) {
    padding: 11px 24px;
  }
}

.slider {
  margin: auto;
  width: 100%;
  height: 100%;
  @media (--viewportMedium) {
    max-width: 1300px;
  }
}

.desktopSlidesView {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider > div {
  margin: 24px;
}

.slideContent {
  margin: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.slideSection {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    flex: 1 0 50%;
  }
}

.slideIcon img {
  height: 45px;
  margin-right: 1em;
  @media (--viewportMedium) {
    height: 72px;
  }
}

.slideInfo h4 {
  @apply --marketplaceH3FontStyles;
  margin: 0px;
  color: var(--marketplaceColor);
  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.slideInfo p {
  margin: 0px;
  font-size: 14px;
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.tokensIcon img,
.videoplayerIcon img {
  width: 100%;
  height: auto;
  @media (--viewportMedium) {
    height: 250px;
    width: auto;
  }
}

.tokensSlideWrapper,
.videoSlideWrapper {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tokensSlide,
.videoSlide {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  max-width: 85%;
  align-self: stretch;
  justify-content: space-evenly;
}

.tokensSlide h4,
.videoSlide h4 {
  @apply --marketplaceH3FontStyles;
  margin: 0;
  color: var(--marketplaceColor);
}

.tokensSlide p,
.videoSlide p {
  font-size: 14px;
  margin: 5px 0px 0px 0px;
}

.banners {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 245px;
  border-radius: 7px;
  padding: 20px;
  position: relative;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    height: 320px;
    width: 100%;
    padding-left: 40px;
  }
  @media (--viewportLarge) {
    padding-top: 30px;
    padding-left: 60px;
    height: 350px;
  }
}

.bannerDataContainer {
  max-width: 220px;

  @media (--viewportMedium) {
    max-width: 400px;
  }
  @media (--viewportLarge) {
    max-width: 677px;
  }
  & h1 {
    color: var(--matterColorLight);
    font-size: 24px;
    margin-top: 16px;
    line-height: 21px;
    @media (--viewportMedium) {
      font-size: 33px;
      margin-top: 20px;
      margin-bottom: 25px;
      line-height: 44px;
    }
    @media (--viewportLarge) {
      font-size: 45px;
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }

  & p {
    color: var(--matterColorLight);
    font-size: 16px;
    max-width: 152px;
    line-height: 1.12;
    @media (--viewportMedium) {
      font-size: 20px;
      max-width: 350px;
      line-height: 1.3;
    }
    @media (--viewportLarge) {
      max-width: 500px;
    }
  }
}

.secondaryButton {
  @apply --marketplaceButtonStyles;
  background-color: var(--matterColorLight);
  color: var(--marketplaceColor);
  padding: 0;
  min-height: 22px;
  width: 165px;
  display: flex;
  align-items: center;
  padding: 3px 18px;
  border-radius: 8px;
  font-size: 13px;
  position: absolute;
  bottom: 40px;

  @media (--viewportMedium) {
    font-size: 19px;
    min-height: 32px;
    width: 244px;
    padding: 10px 35px;
    bottom: 55px;
  }

  @media (--viewportLarge) {
    font-size: 24px;
    width: 323px;
    padding: 10px 44px;
    bottom: 70px;
  }

  &:hover {
    background-color: var(--matterColorNegative);
    color: var(--marketplaceColor);
  }
}

.primaryButton {
  @apply --marketplaceButtonStyles;
  padding: 0;
  min-height: 22px;
  width: 165px;
  display: flex;
  align-items: center;
  padding: 3px 18px;
  border-radius: 8px;
  font-size: 13px;
  position: absolute;
  bottom: 40px;

  @media (--viewportMedium) {
    font-size: 19px;
    min-height: 32px;
    width: 244px;
    padding: 10px 35px;
    bottom: 55px;
  }

  @media (--viewportLarge) {
    font-size: 24px;
    width: 323px;
    padding: 10px 44px;
    bottom: 70px;
  }
}

.rightArrow {
  padding-left: 3px;
  height: 30px;
  width: 21px;
  display: flex;
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    padding-left: 5px;
    height: 55px;
    width: 45px;
  }
}
