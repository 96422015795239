@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  @apply --backgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1350px;
  margin: auto;
  /* padding-bottom: 297px; */
  @media (--viewportMedium) {
    padding-bottom: 0px;
    flex-direction: row;
    align-items: center;
  }
}

.heroContent {
  margin: 0;

  padding: 20px 5px 5px 5px;

  @media (--viewportMedium) {
    padding: 0 0px 1px 0px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 5px 20px 5px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    /* max-width: 1350px; */
    width: 100%;
    padding: 0 10px 0 10px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--marketplaceColor);
  composes: animation;
  display: inline;
  animation-delay: 0.5s;
  font-weight: 700;
  margin: 20px 0 0 0;
  font-size: 45px;
  line-height: 42px;
  letter-spacing: -0.5px;
  padding: 2px 5px;
  & p {
    margin-top: 0px;
  }
  /* View port for Iphone 5 */
  @media only screen and (min-width: 250px) and (max-width: 340px) {
    font-size: 35px;
  }
  @media (--viewportMedium) {
    max-width: 500px;
    min-width: 300px;
    margin: 0px;
    line-height: 42px;
    font-size: 55px;
  }
  @media only screen and (min-width: 768px) and (max-width: 807px) {
    font-size: 47px;
  }
  @media (--viewportLarge) {
    max-width: 500px;
    min-width: 330px;
    font-size: 70px;
    line-height: 85px;
    letter-spacing: -2.5px;
  }
  @media only screen and (min-width: 1196px) {
    font-size: 90px;
  }
  @media only screen and (min-width: 1070px) and (max-width: 1090px) {
    font-size: 65px;
  }
}

.typeWriter {
  color: var(--matterColorDark);
  background: var(--matterColorGolden);
  display: inline-block;
  border-radius: 10px;
  padding: 5px 5px;
  font-size: 27px;
  margin: 0px;
  font-weight: 700;
  letter-spacing: -0.5px;
  @media (--viewportMedium) {
    font-size: 40px;
    padding: 3px 5px;
  }
  @media (--viewportLarge) {
    font-size: 44px;
    letter-spacing: -2.5px;
  }
}

.heroCategoreyName {
  margin: 0px;
  line-height: 52px;
  @media (--viewportLarge) {
    line-height: 90px;
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;
  letter-spacing: 1.5px;
  color: var(--matterColor);
  margin: 30px 0px;
  composes: animation;
  animation-delay: 0.65s;
  font-weight: normal;
  font-size: 16px;
  /* View port for Iphone 5 */
  @media only screen and (min-width: 300px) and (max-width: 340px) {
    font-size: 15px;
  }

  @media (--viewportMedium) {
    max-width: 400px;
    margin: 20px 0 20px 0;
    font-weight: lighter;
    font-size: 16px;
  }
  @media (--viewportLarge) {
    margin: 20px 0 10px 0;
    max-width: 400px;
    line-height: 30px;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: inline-block;
    margin-top: 30px;
    width: 180px;
  }
}

.userOwnedMarketplaceButton {
  @apply --marketplaceButtonStyles;
  composes: animation;
  margin-right: 20px;
  border: 2px solid var(--matterColorLight);
  background: var(--matterColorLight);
  color: var(--marketplaceColor);

  &:hover,
  &:focus {
    outline: none;
    background-color: var(--matterColorNegative);
    text-decoration: none;
  }

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: inline-block;
    width: 260px;
  }
}

.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    max-width: 100%;
    margin-bottom: 0px;
  }
  @media (--viewportLarge) {
    margin-top: 30px;
    max-width: 412px;
  }
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 1rem;
  margin-top: 1rem;
  color: red;
  font-weight: normal;
  font-size: 1rem;
}
.noResults {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 1rem;
  margin-top: 1rem;
  color: var(--matterColorDark);
  font-weight: normal;
  font-size: 1rem;
}
.icon {
  font-size: 30px;
  margin-right: -0.25rem;
  color: var(--marketplaceColor);
}
.left {
  font-size: 30px;
  margin-right: 0.35rem;
  color: var(--marketplaceColor);
}
/* .desktop {
  display: none;
  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0.5rem;
  }
} */
.mainContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin-top: unset;
  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
    width: 95%;

    /* margin-top: -40px; */
  }
  @media (--viewportXLarge) {
    width: 100%;
  }
}
.rightContainer {
  width: 100%;
  /* padding: 1rem; */
  border: unset;
  position: relative;
  box-shadow: unset;
  border-radius: var(--borderRadius);
  border-radius: 15px;
  @media (--viewportMedium) {
    /* border: 1px solid var(--matterColorLight); */
    /* box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1); */
  }
  @media (--viewportLarge) {
    width: 100%;
    max-width: 450px;
    height: 90%;
  }
  @media (--viewportLargeWithPaddings) {
    width: 100%;
    max-width: 500px;
  }
  @media (--viewportXLarge) {
    max-width: 800px;
  }
}

.desktop {
  display: none;
  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto; /* Adjust rows automatically */
    gap: 0.75rem;
    max-height: calc(4 * 120px + 0.5rem); /* Adjust based on item height */
    overflow-y: auto;
  }
  @media (--viewportLargeWithPaddings) {
    grid-template-columns: 1fr 1fr;
  }
  @media (--viewportXLarge) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.desktop::-webkit-scrollbar {
  display: none;
}
.rightArrow {
  display: none;
  @media (--viewportLarge) {
    display: block;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    border-radius: 50%;
    background-color: white;
    padding: 1rem 1.2rem;
    border: 2px solid var(--marketplaceColor);
  }
}
.leftArrow {
  display: none;
  @media (--viewportLarge) {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    border-radius: 50%;
    background-color: white;
    padding: 1rem;
    border: 2px solid var(--marketplaceColor);
  }
}
.mobile {
  display: flex;
  min-width: 100%;
  overflow: scroll;
  gap: 1rem;
  @media (--viewportLarge) {
    display: none;
  }
}
.listingCardsMobile {
  min-width: 80%;
  /* border: 1px solid; */
  height: auto;
  margin-bottom: 1rem;
  margin-left: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 15px;
}
.listingCards {
}
.desktopMain {
  width: unset;
  @media (--viewportLarge) {
    width: 100%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    /* margin: auto; */
  }
}
