@import '../../styles/propertySets.css';

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 2px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 24px auto;
  }
}

@keyframes animation_howItWorks {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.animation {
  animation-name: animation_howItWorks;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.howItWorksBtn {
  @apply --marketplaceButtonStyles;
  background-color: var(--matterColorGolden);
  composes: animation;
  animation-delay: 0.8s;
  padding: 0px 25px;
  min-height: 32px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  color: black;
  &:hover,
  &:focus {
    background-color: var(--matterColorDarkGolden);
  }
}

.rightArrow {
  padding-left: 5px;
  height: 55px;
  width: 30px;
  display: flex;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.contentMain {
  @media (--viewportMedium) {
    /* max-width: 650px; */
  }
}

.buttonLink {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
