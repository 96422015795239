@import '../../styles/propertySets.css';

.pageTitle {
  margin-top: 0px;
  font-size: 24px;
  @media (--viewportMedium) {
    font-size: 1.75em;
    line-height: 40px;
  }
}

.paragraph{
  color: var(--matterColorMediumGrey);
  font-size: 18px;
  font-weight: normal;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 48px auto;
  }
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 100%;
  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.maintenancePlansListItems{
  position:relative; 
  left : 30px;
  list-style: disc;

  & li{
      font-size: 16px;
  }
}

@keyframes animation_userOwnership {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.animation {
  animation-name: animation_userOwnership;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.maintenancePlansBtn{
  @apply --marketplaceButtonStyles;
  background-color: var(--matterColorGolden);
  composes: animation;
  animation-delay: 0.8s;
  padding: 0px 25px;
  min-height: 32px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  border-radius: 8px;

  &:hover,
  &:focus {
    background-color: var(--matterColorDarkGolden);
  }
}

.tirePlansBtn{
  @apply --marketplaceButtonStyles;
  background-color: var(--matterColorGolden);
  composes: animation;
  animation-delay: 0.8s;
  padding: 0px 25px;
  min-height: 32px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  &:hover,
  &:focus {
    background-color: var(--matterColorDarkGolden);
  }
}

.carWashPlansBtn{
  @apply --marketplaceButtonStyles;
  background-color: var(--matterColorGolden);
  composes: animation;
  animation-delay: 0.8s;
  padding: 0px 25px;
  min-height: 32px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  /* View port for Small phones */
  @media only screen and (min-width: 300px) and (max-width: 375px){
    padding: 0px 8px;
  }

  &:hover,
  &:focus {
    background-color: var(--matterColorDarkGolden);
  }
}

.rightArrow{
  padding-left:5px;
  height: 55px;
  width:30px;
  display: flex;
}

.buttonContainer{
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 20px;

  @media(--viewportMedium){
    justify-content: flex-end;
  }
}

.container{
  display: flex;
  flex-direction: row;
}

.imgsContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 1px;
}

.textContainer{
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  justify-content: space-between;
}

.heading{
  font-size: 16px;
  color: black;
  margin: 0;
}

.subHeading{
  color: var(--marketplaceColor);
  font-size: 17px;
  font-weight: bold;
  margin-top: 0px;
}

.blueLine{
  height: 10px;
  display: inline-block;
  margin: 0px;
  width: 5%;
  content: '';
  border-bottom: 4px solid var(--marketplaceColorLight);
  @media (--viewportMedium) {
    width: 2.5%;
  }
}

.blueLineMain{
  height: 10px;
  display: inline-block;
  margin: 0px;
  width: 5%;
  content: '';
  border-bottom: 4px solid var(--marketplaceColorLight);
  margin-bottom: 5px;
  @media (--viewportMedium) {
    width: 2%;
  }
  @media (--viewportLarge) {
    width: 1.7%;
  }
}

.maintenanceImage{
  height: 265px;
  margin-left: 5px;
  margin-top: 20px;
  @media (--viewportMedium) {
    margin-left: 100px;
  }
}

.tyreImage{
  height: 240px;
  margin-left: 5px;
  margin-top: 20px;
  @media (--viewportMedium) {
    margin-left: 100px;
  }
}

.carWashImage{
  height: 240px;
  margin-left: 5px;
  margin-top: 20px;
  @media (--viewportMedium) {
    margin-left: 100px;
  }
}

.buttonLink{
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}