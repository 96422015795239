.sectionTitle{
  margin-top: 0px;
}

.blueLine{
  height: 10px;
  display: inline-block;
  margin: 0px;
  width: 5%;
  content: '';
  border-bottom: 4px solid var(--marketplaceColorLight);
  @media (--viewportMedium) {
    width: 4%;
  }
  @media (--viewportLarge) {
    width: 3%;
  }
}

.paragraph{
  font-size: 17px;
  margin-top: 0px;
}

.contentWrapper{
  display: flex;
  flex-direction: column;
  
  @media (--viewportMedium) {
    align-items: center;
  }
}

.image{
  height: 1000px;
  margin: 30px 0px;
}





