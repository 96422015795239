@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  /* border: 1px solid black; */
  /* Remove link's hover effect */
  /* &:hover {
    text-decoration: none;
  } */
  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
    text-decoration: none;
  }
  box-shadow: 0 2px 4px 0 rgb(136 144 195 / 20%), 0 5px 15px 0 rgb(37 44 97 / 15%);
}
.rootLanding {
  /* Layout */
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  /* border: 1px solid black; */
  /* Remove link's hover effect */
  /* &:hover {
    text-decoration: none;
  } */
  &:hover {
    /* transform: scale(1.02); */
    /* box-shadow: var(--boxShadowListingCard); */
    text-decoration: none;
  }
  /* box-shadow: 0 2px 4px 0 rgb(136 144 195 / 20%), 0 5px 15px 0 rgb(37 44 97 / 15%); */
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  border-radius: 15px 15px 0px 0px;
  /* &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  } */
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 15px 15px 0px 0px;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
  border-radius: 15px 15px 0px 0px;
}
.rootForImageLanding {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
  border-radius: 15px 15px 0px 0px;
}

.infoLanding {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px 20px 0px;
  /* View port for Iphone 5 */
  @media only screen and (min-width: 200px) and (max-width: 375px) {
    padding: 5px 0px 0px 3px;
  }
}
.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px 5px 0px;
  /* View port for Iphone 5 */
  @media only screen and (min-width: 200px) and (max-width: 375px) {
    padding: 5px 0px 0px 3px;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 6px;
  /* View port for Iphone 5 */
  @media only screen and (min-width: 200px) and (max-width: 375px) {
    margin-right: 10px;
  }
  @media (--viewportMedium) {
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 10px;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-size: 30px;
  line-height: 1.2;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* View port for Iphone 5 */
  @media only screen and (min-width: 351px) and (max-width: 375px) {
    font-size: 25px;
  }
  @media only screen and (min-width: 200px) and (max-width: 350px) {
    font-size: 17px;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 27px;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1099px) {
    font-size: 25px;
  }
}

.priceValue h3 {
  margin-top: 0;
  color: var(--matterColor);
  font-size: 16px;
  padding-top: 15px;
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  text-align: left;
}

.authorName {
  margin: 0 13px 0 13px;
  font-size: 19px;
  font-style: italic;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  /* width: 212px; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* View port for Iphone 5 */
  @media only screen and (min-width: 200px) and (max-width: 350px) {
    font-size: 16px;
    /* width: 170px; */
  }
  /* View port for Iphone X */
  @media only screen and (min-width: 351px) and (max-width: 375px) {
    font-size: 16px;
    /* width: 170px; */
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    -webkit-line-clamp: 2;
    /* width: 200px; */
  }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    /* width: 160px; */
  }
  @media only screen and (min-width: 1240px) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 22px;
    -webkit-line-clamp: 1;
    /* width: 218px; */
  }
}
.titleSmall {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  font-size: 16px;
  margin: 0;
  line-height: 1.2;
}

.location {
  color: var(--matterColor);
  font-style: italic;
  font-size: 13px;
  /* width: 160px; */
  line-height: 1.2;
  /* View port for Iphone 5 */
  @media only screen and (min-width: 200px) and (max-width: 350px) {
    /* width: 140px; */
  }
  /* View port for Iphone X */
  @media only screen and (min-width: 351px) and (max-width: 375px) {
    /* width: 150px; */
  }
  @media (--viewportMedium) {
    /* width: 180px; */
  }
  @media only screen and (min-width: 1240px) {
    /* width: 212px; */
  }
}
.locationSmall {
  color: var(--matterColor);
  font-style: italic;
  font-size: 12px;
  line-height: 1.2;
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  font-size: 16px;
  font-style: italic;
  font-weight: 100 !important;

  /* Remove default margins from font */
  margin-top: 5px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.authorRating {
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: var(--matterColor);
  & span {
    margin-left: 3px;
    margin-top: 5px;
  }
}
.authorRatingSmall {
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: var(--matterColor);
  & span {
    margin-left: 3px;
  }
}

.authorContainer {
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.priceContainer {
  display: flex;
  padding-right: 5px;
  /* View port for Iphone 5 */
  @media only screen and (min-width: 200px) and (max-width: 340px) {
    padding-right: 0px;
  }
}

.labor {
  font-size: 11px;
  font-weight: 100;
  max-width: 80px;
  line-height: 1.2;
  padding-left: 5px;
  /* color: var(--matterColorAnti); */
  color: var(--matterColorMediumGrey);
  margin: 0;
  /* View port for Galaxy Fold */
  @media only screen and (min-width: 200px) and (max-width: 340px) {
    max-width: 50px;
    padding-left: 5px;
  }
}
.star {
  font-size: 40px;
  fill: var(--matterColorGolden);
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  margin-right: 0.25rem;
  border-radius: 20px;
}
.starSmall {
  font-size: 20px;
  fill: var(--matterColorGolden);
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  margin-right: 0.25rem;
  border-radius: 20px;
}
.distance {
  display: flex;
  justify-content: flex-end;
  margin-right: 0.25rem;
}
.dist {
  color: var(--matterColor);
  font-size: 14px;
}
.amRatingText {
  font-size: 18px;
  font-weight: 600;
}
.amRatingTextSmall {
  font-size: 15px;
  font-weight: 600;
}
.error {
  color: red;
  font-size: 12px;
  margin: 0;
  /* margin-top: 5px; */
}
.locationIcon {
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
  font-size: 15px;
}
.textSmall {
  font-size: 13px;
  margin: 0;
  line-height: 1.2;
}
