@import '../../styles/propertySets.css';

.pageTitle {
  text-align: left;
  font-size: 28px;
  margin-bottom: 7px;
 
  @media (--viewportMedium) {
    font-size: 40px;
  }
}

.span{
  border-top: 4px solid var(--marketplaceColor);
  padding-top: 5px;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium){
    width: calc(100% - 72px);
    margin: 48px auto;
  }
}

.buttonContainer{
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.animation {
  animation-name: animationCurrentOffers;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.currentOffersBtn
{ 
  @apply --marketplaceButtonStyles;
  background-color: var(--matterColorGolden);
  composes: animation;

  animation-delay: 0.8s;
  min-height: 32px;
  width:162px;
  margin-top: 55px;
  display: flex;
  align-items: center;
  padding: 10px 25px 10px 25px;
  border-radius: 8px;

  &:hover,
  &:focus {
    background-color: var(--matterColorDarkGolden);
  }  
}

@keyframes animationCurrentOffers{
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.paragraphContent{
  font-size: 20px;
  color: var(--matterColorMediumGrey);
  font-weight: normal;
}

.contentMain {
  width: 90%;

  @media (--viewportMedium){
    max-width: 650px;
  }
}

.buttonLink{
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}