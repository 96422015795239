.pdfViewer {
  width: 100%;
  height: 600px;
  max-width: 800px;
  margin: 0 auto;
  overflow: auto;
  @media (--viewportMedium) {
    width: 800px;
  }
}
