@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}

.inputFontSize {
  font-size: 16px;
  border-bottom-color: var(--attentionColor);
}
.submitButton {
  font-size: 25px;
  font-weight: 700;
  @media (--viewportMedium) {
    font-size: 30px;
  }
}
