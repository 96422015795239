.profileMenuLabel {
  border-bottom: 0px solid;
  /* max-width:100px; */
  transition: var(--transitionStyleButton);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: right;
  /* border:1px solid #4A4A4A; */
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;
  position: relative;

  /* &:hover {
      border-bottom: 4px solid var(--marketplaceColor);
      border-radius: 0;
      text-decoration: none;
    } */
  &:active {
    border-bottom: 0;
  }
}
.profileMenuContentt {
  /* min-width: 276px !important; */
  margin-top: 7px;
  /* width: 150px !important;; */
  padding: 10px 10px 6px 10px;
  right: 0;
}
.shareButton {
  composes: buttonSecondaryInline from global;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px 5px 16px;
  color: #4a4a4a;
  height: 40px;
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  cursor: pointer;
  margin-top: 0px;
}
.profileMenuContentt span {
  padding-left: 5px;
}
.profileMenuContentt div {
  border-bottom: 1px solid transparent;
  margin-bottom: 4px;
  padding: 2px 7px;
  &:hover {
    background-color: rgba(0,0,0,.125);
    border-radius: 3px;
    /* border-color: var(--marketplaceColor); */
  }
}
.instaShare {
  color: var(--colorGrey700);
  &:hover {
    text-decoration: none;
    /* border:2px solid green; */
  }
}
.profileMenuContenttt {
  /* min-width: 276px; */
  margin-top: 7px;
  width: 150px;
  padding: 10px;
  color: red;
  border: 20x solid red;
}
.profileShareError {
  margin: 0;
  padding: 0;
}
.copyLink {
  display: flex;
  align-items: center;
  /* height:20px; */
  margin: 0;
  padding: 0 0 0 7px;
  background-color: var(--colorGrey50);
  border-bottom: 1px solid transparent;
  border-radius: 0px;
  color: rgb(74, 74, 79);
  &:hover {
    background-color: var(--colorGrey50);
    color: rgb(74, 74, 79);
    box-shadow: none;
    border-color: var(--marketplaceColor);

    /* border:2px solid green; */
  }
}
.shareIcon {
  margin-right: 4px;
}
.share {
  text-decoration: underline;
  font-weight: 800;
  font-size: 18px;
}

.parent {
  position: relative;
}
