@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: flex;
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorLight);
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.input {
  flex-grow: 1;
  height: var(--LocationAutocompleteInput_inputHeight);
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px;
  color: var(--matterColorLight);

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportLarge) {
    stroke-width: 2px;
  }
}

.iconSpinner {
  margin: auto;
  width: 23px;
}

.mobileInputRoot {
  width: 100%;
}

.mobileIcon {
  padding: 16px 18px 16px 0;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  display: flex;
  width: 40px;
  align-self: stretch;
  background-color: var(--marketplaceColor);
  border-top-right-radius: 42px;
  border-bottom-right-radius: 42px;
}

.mobileInput {
  flex-grow: 1;

  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorMediumGrey);

  /* Layout */
  margin: 0;
  padding-left: 10px;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;
  font-size: 17px;
  font-weight: 500;

  /* Borders */
  border: none;
  @media (--viewportMedium) {
    font-size: 16px;
    padding: 30px 0px 30px 20px;
  }
  @media (--viewportLarge) {
    padding: 0px 0px 0px 18px;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
    color: var(--matterColorAnti);
  }

  &:hover,
  &:focus {
    outline: none;
  }
}

.desktopIcon {
  /* height: var(--topbarHeightDesktop); */
  height: 55px;
  border: none;
  padding-top: 3px;
  display: flex;
  /* width: 24px; */
  align-self: stretch;
  border-radius: 8px;
  z-index: 1;
  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.desktopInput {
  flex-grow: 1;
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorMediumGrey);
  /* height: var(--topbarHeightDesktop); */
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 12px;
  font-size: 16px;
  width: 100%;
  @media (min-width: 1200px) {
    width: 180px;
  }
  @media (min-width: 1300px) {
    width: 300px;
  }
  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColorMediumGrey);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    color: var(--matterColorAnti);
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightLarge);
    transition: var(--transitionStyleButton);
  }
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 45px;
  margin-right: 0px;
  @media only screen and (min-width: 1025px) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 18px;

    border-radius: 0px;
  }
}

.dividingBorder {
  @media (--viewportLarge) {
    display: inline-block;
    width: 0;
    height: 1.5em;
    margin-top: 25px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    display: none;
  }
}

.searchButton {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  font-size: 16px;
  border-bottom-right-radius: 45px;
  border-top-right-radius: 45px;
  width: 69px;
  padding: 15px 0 0 6px;
}

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(
    100vh -
      calc(
        var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  );
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 64px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  max-width: 434px;
}

.queryField {
  border-left: none;
  @media (--viewportLarge) {
    min-width: 150px;
  }
  @media only screen and (min-width: 1023px) and (max-width: 1200px) {
    min-width: 100px;
  }
}

.searchInputs {
  width: 100%;
  height: 55px;
  border: 3px solid var(--marketplaceColor);
  margin: 1.05rem 0;
  border-radius: 30px;
}

.buttonHolder {
  padding: 20px 0px;

  @media (--viewportLarge) {
    padding: 8px 5px;
  }
}

.searchBtn {
  border-radius: 10px;
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);
  font-size: 23px;
  min-height: 55px;
  @media (--viewportLarge) {
    padding: 10px 30px;
  }
  &:hover,
  &:focus {
    background-color: var(--marketplaceColorDark);
  }
}

.searchBox {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  @media (--viewportLarge) {
    padding: 0px 5px 0px 5px;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.searchBoxRowOne {
  display: flex;
  align-items: center;
  border: 1px solid var(--matterColorLight);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 0px 3px;
  height: 50px;
  @media (--viewportLarge) {
    border: none;
    box-shadow: none;
    border-radius: 0px;
    margin-bottom: 0px;
    padding: 0px;
    width: 50%;
  }
}

.searchBoxRowTwo {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--matterColorLight);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 10px;
  padding: 0px 3px;
  height: 50px;
  @media (--viewportLarge) {
    border: none;
    box-shadow: none;
    border-radius: 0px;
    margin-top: 0px;
    padding: 0px;
    width: 43%;
  }
}

.icons {
  height: 36px;
  filter: invert(24%) sepia(0%) saturate(1575%) hue-rotate(227deg) brightness(98%) contrast(80%);
}
.searchIcon {
  font-size: 40px;
}
