.sectionTitle {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 27px;
  @media (--viewportMedium) {
    font-size: 1.75em;
    line-height: 40px;
  }
}

.blueLine {
  height: 10px;
  display: inline-block;
  margin: 0px;
  width: 6%;
  content: '';
  border-bottom: 4px solid var(--marketplaceColorLight);
  @media (--viewportMedium) {
    width: 3%;
  }
  @media (--viewportLarge) {
    width: 3%;
  }
}

.paragraph {
  color: var(--matterColorMediumGrey);
  font-weight: normal;
  font-size: 18px;
  margin-top: 0px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (--viewportMedium) {
    align-items: center;
    margin-top: 0px;
  }
}

.pays200Receives176 {
  height: auto;
  width: 100%;
  @media (--viewportMedium) {
    height: 100%;
    width: 100%;
  }
}
/* .imageContainer {
  width: auto;
  height: 200px;
} */
.colorRed {
  color: var(--failColor);
}
