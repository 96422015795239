.root {
  position: relative;
}

.scrollContainer {
  display: flex;
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation;

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
  }
}

.scrollArrow {
  -webkit-overflow-scrolling: touch;
  @media (--viewportMobile) {
    display: none;
  }
}
