@import '../../styles/propertySets.css';

.pageTitle {
  @apply --marketplaceH3FontStyles;
  text-align: left;
  color: var(--marketplaceColorLight);
  font-size: 21px;

  @media (--viewportMedium) {
    font-size: 1.75em ;
  }
}

.underline{
  border-bottom: 3px solid var(--highlightColor);
  margin-top: -2px;
  padding-bottom: 5px;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 100%;
  color: black;
  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.paragraph{
  font-size: 16px;
  margin-top: 0px;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.ethereumLink{
  color:black;
  border-bottom: 1px solid black;

  &:hover{
    border-bottom: none;
  }
}

.userOwnedTitle{
  @apply --marketplaceH3FontStyles;
  padding-bottom: 5px;
  text-align: left;
  color: var(--marketplaceColorLight);
  font-size: 19px;

  @media (--viewportMedium) {
    font-size: 1.75em ;
  }
}

@keyframes animation_userHowItWorks {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}


.animation {
  animation-name: animation_userHowItWorks;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.userOwnershipBtn
{ 
  @apply --marketplaceButtonStyles;
  composes: animation;

  animation-delay: 0.8s;
  padding: 0;
  min-height: 32px;
  width:180px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding-left: 25px;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    margin-top: 80px;
  }
}

.rightArrow{
  padding-left:5px;
  height: 55px;
  width:30px;
  display: flex;
}

.imageContainer{
  display: flex;
  align-items: center;
  @media (--viewportMedium){
    display: flex;
    align-items: center;
  }
}

.marketplaceImage{
  width:auto;
  height:132px;
  padding: 5px 0 5px 0;

  @media (--viewportMedium) {
    padding: 10px 0 10px 0;
    width:auto;
    height:235px;
    margin-left:32px;
  }
}

.ownershipImage{
  width:auto;
  height:187px;
  padding-top: 10px;
  margin-bottom: 42px;

  @media (--viewportMedium) {
    width:auto;
    height:349px;
  }
}

.buttonContainer{
  display: flex;
  justify-content: flex-end;
}