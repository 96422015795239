.root {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  cursor: pointer;
  & > .img {
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    background-image: url('../../assets/arrow_btn.png');
    background-size: contain;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    display: none;
    @media (--viewportMedium) {
      display: flex;
    }
  }
 
}

.left {
  left: -15px;
  & > .img {
    transform: translateY(-50%) rotate(180deg);
  }
}

.right {
  right: -18px;
}
