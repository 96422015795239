@import '../../styles/propertySets.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: inherit;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 250px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  @apply --marketplaceH1FontStyles;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Logout font is smaller and gray since the action is not recommended. */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  /* Position component */
  width: max-content;
  margin: 6px 0 30px 0;
  padding: 0;
}

.aboutUs,
.userOwned,
.HowItWorks,
.automktRewards,
.blockchain,
.earningTokens,
.manageYourMaintenance,
.web3Marketplace,
.bnplPage,
.evPage,
.dataPage,
.newsletter {
  color: var(--matterColorDark);
  position: relative;
  /* margin-bottom: 11px; */
  font-size: 22px;
  line-height: 40px;
  margin-left: 1.5rem;
  padding-left: 0px;
}

.settings {
  color: var(--marketplaceColor);
  position: relative;
  margin: 11px 0px;
  font-size: 22px;
  display: block;
}

.inbox {
  /* Font */
  color: black;
  position: relative;
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 22px;
  background-color: var(--matterColorGolden);
  width: 150px;
  text-align: center;
  padding: 0.65rem;
  border-radius: 12px;
}
.yellowLine {
  border-top: 4px solid var(--matterColorGolden);
  margin-top: 2rem;
}
.navigationLink {
  /* Font */
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
  font-size: 20px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  left: 55px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  @apply --marketplaceButtonStyles;
  /* background-color: var(--matterColorGolden); */
  background-color: var(--successColor);
}

.authenticationGreeting {
  /* Font */
  @apply --marketplaceH1FontStyles;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.arrowIcon {
  float: right;
}
.plusIcon {
  color: black;
  cursor: pointer;
}
.plusIconC {
  color: black;
  cursor: pointer;
  font-size: 38px;
  background-color: var(--matterColorGolden);
  /* padding: 0.5rem; */
  border-radius: 30px;
}
.category {
  color: black;
}
