.sectionTitle {
  margin-top: 0px;
  font-size: 27px;
  @media (--viewportMedium) {
    font-size: 1.75em;
    line-height: 40px;
  }
}

.blueLine {
  height: 10px;
  display: inline-block;
  margin: 0px;
  width: 6%;
  content: '';
  border-bottom: 4px solid var(--marketplaceColorLight);
  @media (--viewportMedium) {
    width: 3%;
  }
  @media (--viewportLarge) {
    width: 3%;
  }
}

.paragraph {
  color: var(--matterColorMediumGrey);
  font-weight: normal;
  font-size: 18px;
  margin-top: 0px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    /* align-items: center; */
  }
}

.image {
  height: auto;
  width: 100%;
  @media (--viewportMedium) {
    margin: 30px 0px;
    height: 340px;
    width: auto;
  }
  @media (--viewportLarge) {
    height: 370px;
  }
}

.list {
  margin-left: 20px;
  list-style-type: disc;
  margin-bottom: 15px;
}

.listItem {
  color: var(--matterColorMediumGrey);
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
}

.highlightRed {
  color: var(--matterColorDark);
}
.highLight {
  background-color: var(--matterColorGolden);
  font-size: 18px;
  color: black;
  padding: 0.25rem;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.star {
  font-size: 30px;
  fill: var(--matterColorGolden);
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  margin-right: 0.25rem;
  border-radius: 20px;
  margin-bottom: 0.25rem;
}
