.pageTitle {
  text-align: left;
  font-size: 25px;
  width:max-content;
  margin-bottom: 0;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium){
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}