.sectionTitle{
  margin-top: 0px;
  @media (--viewportMedium) {
    font-size: 1.75em;
    line-height: 40px;
  }
}

.blueLine{
  height: 10px;
  display: inline-block;
  margin: 0px;
  width: 5%;
  content: '';
  border-bottom: 4px solid var(--marketplaceColorLight);
  @media (--viewportMedium) {
    width: 3%;
  }
  @media (--viewportLarge) {
    width: 3%;
  }
}

.paragraph{
  color: var(--matterColorMediumGrey);
  font-size: 18px;
  font-weight: normal;
  margin-top: 0px;
}

.boldText{
  font-size: 17px;
  margin-top: 0px;
  font-weight: 600;
  margin-bottom: 30px;
  @media (--viewportMedium) {
    margin-bottom: 0px;
  }
}

.contentWrapper{
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    align-items: center;
  }
}

.image{
  height: auto;
  width: 100%;
  @media (--viewportMedium) {
    margin: 30px 0px;
    height: 290px;
    width: auto;
  }
  @media (--viewportLarge) {
    height: 350px;
  }
}




